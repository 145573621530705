<template>
  <Breadcrumbs title="돌봄받는자" main="현황" />
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <h4 class="card-title mb-0">돌봄받는자 현황</h4>
        </div>
      </div>

      <div class="col-xxl-12 col-ed-12 col-xl-12 col-sm-12 box-col-12">
        <EasyDataTable table-class-name="custom-data-table" buttons-pagination :search-value="searchValue" :search-field="'targetNm'" :filter-options="filterOptions" :headers="Headers" :items="flatItems" @click-row="targetDetailMonitoring($event)" show-index>
          <template #item-careTargetNm="{ careTargetNm, careTargetNm_label }">
            <td :data-label="careTargetNm_label">
              {{ careTargetNm }}
            </td>
          </template>

          <template #item-birthDt="{ birthDt, birthDt_label }">
            <td :data-label="birthDt_label">
              {{ birthDt }}
            </td>
          </template>
          <template #item-gender="{ gender, gender_label }">
            <td :data-label="gender_label">
              {{ formatGender(gender) }}
            </td>
          </template>
          <template #item-pressure="{ pressure, pressure_label }">
            <td :data-label="pressure_label">
              {{ pressure }}
            </td>
          </template>
          <template #item-pulse="{ pulse, pulse_label }">
            <td :data-label="pulse_label">
              {{ pulse }}
            </td>
          </template>

          <template #item-bodyTemperature="{ bodyTemperature, bodyTemperature_label }">
            <td :data-label="bodyTemperature_label">
              {{ bodyTemperature }}
            </td>
          </template>

          <template #item-oxygenSaturation="{ oxygenSaturation, oxygenSaturation_label }">
            <td :data-label="oxygenSaturation_label">
              {{ oxygenSaturation }}
            </td>
          </template>

          <template #item-steps="{ steps, steps_label }">
            <td :data-label="steps_label">
              {{ steps }}
            </td>
          </template>

          <template #item-guardianNm="{ gNm, gNm_label }">
            <td :data-label="gNm_label">
              {{ gNm }}
            </td>
          </template>
          <template #item-gRelation="{ gRelation, gRelation_label }">
            <div v-if="gRelation == '1'">
              <td :data-label="gRelation_label">배우자</td>
            </div>
            <div v-if="gRelation == '2'">
              <td :data-label="gRelation_label">자녀</td>
            </div>
            <div v-if="gRelation == '3'">
              <td :data-label="gRelation_label">형제</td>
            </div>
            <div v-if="gRelation == '4'">
              <td :data-label="gRelation_label">친척</td>
            </div>
            <div v-if="gRelation == '5'">
              <td :data-label="gRelation_label">기타</td>
            </div>
          </template>
          <template #item-careUsers="{ careUsers, careUsers_label }">
            <td :data-label="careUsers_label">
              <span :class="getRandomBadgeClass()" style="font-size: 0.8rem">
                {{ formatCareUsers(careUsers) }}
              </span>
            </td>
          </template>
        </EasyDataTable>
        <div class="d-flex justify-content-end">
          <button class="btn m-r-10 btn-primary btn-block" @click="goBack()">뒤로가기</button>
        </div>
      </div>
      <br />
    </div>
  </div>
</template>

<script>
import VueCal from "vue-cal";
import { formatTime, formatDate, formatDateNotHyphen } from "@/utils/dateUtils.js";
import api from "@/api.js";
const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
import * as criteria from "@/data/criteriaData.js";
import { ref, computed } from "vue";
import "vue-cal/dist/vuecal.css";
import "@vuepic/vue-datepicker/dist/main.css";
import srchPrjModal from "@/modal/srchPrjModal.vue";
import { targetMonitoringHeader } from "@/data/tableHeaders.js";
import { getLoginId, getLoginUserRole, formatGender, getLoginPrjId, formatPhone, formatCareUsers, getRandomBadgeClass } from "@/utils/common.js";
export default {
  components: {
    srchPrjModal,
  },
  data() {
    return {
      Headers: targetMonitoringHeader,
      Items: [],
      careTargetId: "",
      formatDateNotHyphen,
      formatDate,
      formatGender,
      formatCareUsers,
      areaId: this.$route.params.areaId,
      prjId: this.$route.params.prjId,
      items: [],
    };
  },

  mounted() {
    this.fetchTagets(this.areaId, this.prjId);
  },
  methods: {
    getRandomBadgeClass,
    goBack() {
      this.$router.go(-1);
    },
    fetchTagets(areaId = "", prjId = "") {
      const url = `/target/monitoring?dt=${formatDateNotHyphen(this.dt)}&areaId=${areaId}`;
      api
        .get(url)
        .then((response) => {
          console.log("areaId", this.areaId);
          console.log("prjId", this.prjId);
          this.items = response.data.map((item) => ({
            ...item,
            data: item.data,
          }));
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "돌봄받는자 현황을 불러오는 중 오류가 발생했습니다.",
            text: error,
            type: "error",
          });
        });
    },
    targetDetailMonitoring(event) {
      const dt = event.dt ? event.dt : new Date().toISOString().split("T")[0]; // Format as 'YYYY-MM-DD'
      this.$router.push({
        name: "targetDetailMonitor",
        params: {
          careTargetId: event.careTargetId,
          dt: dt,
        },
      });
    },
  },
  computed: {
    flatItems() {
      return this.items.map((item) => {
        return {
          ...item,

          ...(item.data || {}), // 데이터가 있는 경우 덮어씌우기
        };
      });
    },
  },
  setup() {
    const dt = ref(new Date());
    return { dt };
  },
};
</script>

<style></style>
