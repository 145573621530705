<template>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">기관 검색</h5>
        <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body mb-4">
        <div class="row d-flex justify-content-end">
          <div class="col-sm-12 col-md-6">
            <label>기관 </label>
            <input class="form-control" type="text" placeholder="검색" v-model="searchValue" />
          </div>
        </div>
        <EasyDataTable table-class-name="custom-data-table" buttons-pagination :search-value="searchValue" @click-row="prjSelected" :search-field="'prjNm'" :headers="Headers" rowsPerPage="10" :items="Items" show-index>
          <template #item-prjNm="{ prjNm, prjNm_label }">
            <td :data-label="prjNm_label">
              {{ prjNm }}
            </td>
          </template>
          <template #item-serviceTy="{ serviceTy, serviceTy_label }">
            <td :data-label="serviceTy_label">
              {{ formatServiceTy(serviceTy) }}
            </td>
          </template>
          <template #item-phone="{ phone, phone_label }">
            <td :data-label="phone_label">
              {{ formatPhoneNumber(phone) }}
            </td>
          </template>
        </EasyDataTable>
      </div>
      <div class="modal-footer">
        <button v-if="!getDefaultPrj" class="btn btn-primary" type="button" data-bs-dismiss="modal" @click="prjReset">초기화</button>
        <button id="close-btn" class="btn btn-secondary" type="button" data-bs-dismiss="modal" @click="clearInput">닫기</button>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api.js";
import { srchPrjHeader } from "@/data/tableHeaders.js";
import { formatPhoneNumber, formatServiceTy } from "@/utils/common.js";
import * as criteria from "@/data/criteriaData.js";
export default {
  name: "SrchCommonPrjModal",
  data() {
    return {
      searchPrjNm: "",
      prjId: "",
      prjNm: "",
      projects: [],
      formatServiceTy,
      formatPhoneNumber,
      Headers: srchPrjHeader,
      Items: [],
      searchValue: "",
      currentSelectedPrjId: null, // Track the selected project ID
      currentSelectedPrjNm: null,
      userRole: "",
      userId: "",
    };
  },
  props: {
    getDefaultPrj: {
      type: Boolean,
      default: false,
    },
    receptionPrjId: {
      type: String,
      default: "",
    },
  },
  methods: {
    /* showModal() {
      console.log("Opening modal and fetching projects");
      this.isModalOpened = true; // Set flag to true when modal opens
      this.isVisible = true; // Show modal
      this.fetchProjects(); // Ensure this is being called
    }, */
    openSurveyModal() {
      if (this.$refs.surveyModal) {
        document.body.style.overflow = "hidden"; // 페이지 스크롤 숨기기
        this.$refs.surveyModal.showModal(this.surveyData);
      } else {
        console.error("모달 컴포넌트가 참조되지 않았습니다.");
      }
    },
    openCounselModal() {
      if (this.$refs.surveyModal) {
        document.body.style.overflow = "hidden"; // 페이지 스크롤 숨기기
        this.$refs.counselModal.showModal(this.counselData); // show() 메서드 호출
      } else {
        console.error("모달 컴포넌트가 참조되지 않았습니다.");
      }
    },
    openProvideModal() {
      if (this.$refs.provideModal) {
        document.body.style.overflow = "hidden"; // 페이지 스크롤 숨기기
        this.$refs.provideModal.showModal(this.providePlanData); // show() 메서드 호출
      } else {
        console.error("모달 컴포넌트가 참조되지 않았습니다.");
      }
    },
    fetchProjects() {
      const url = `/project/commonFind?userId=${this.userId}&userRole=${this.userRole}`;
      //if(this.receptionPrjId) url += `&prjId=${this.receptionPrjId}`;
      api
        .get(url)
        .then((response) => {
          this.Items = this.setDataLabel(response.data);
          this.projects = response.data; // 회원 데이터 할당
          //if ((response.data.length > 0) & this.getDefaultPrj) this.prjSelected(response.data[0]);
          if (response.data.length > 0) {
            if (this.getDefaultPrj && (!this.receptionPrjId || this.receptionPrjId === "")) {
              // getDefaultPrj가 true이고 receptionPrjId가 없거나 빈 문자열이면 첫 번째 항목 선택
              this.prjSelected(response.data[0]);
            } else if (this.getDefaultPrj && this.receptionPrjId) {
              // getDefaultPrj가 true이고 receptionPrjId가 있는 경우 해당 ID에 맞는 항목 선택
              const selectedPrj = response.data.find((project) => project.prjId === this.receptionPrjId);
              if (selectedPrj) {
                this.prjSelected(selectedPrj);
              }
            }
          }
        })
        .catch((error) => {
          console.error("기관 목록을 불러오는 중 오류가 발생했습니다.", error);
          this.$swal({
            icon: "error",
            title: "기관 목록을 불러오는 중 오류가 발생했습니다.",
            text: error,
            type: "error",
          });
        });
    },

    async prjSelected(item) {
      this.$emit("project-selected", item);

      this.prjNm = ""; // Clear project name
      this.prjId = ""; // Clear project ID

      this.$nextTick(() => {
        this.prjNm = item.prjNm; // Update project name
        this.prjId = item.prjId; // Update project ID

        if (item.serviceTy === "1") {
          this.serviceType = "1"; // Set to facility
        } else if (item.serviceTy === "2") {
          this.serviceType = "2"; // Set to home
        } else {
          this.serviceType = "3"; // Set to home
        }

        this.currentSelectedPrjId = item.prjId;
        this.currentSelectedPrjNm = item.prjNm;

        const closeBtn = document.getElementById("close-btn");
        if (closeBtn) {
          closeBtn.click();
        }
      });
    },
    setDataLabel(items) {
      items.forEach((item) => {
        item.prjNm_label = "기관";
        item.phone_label = "연락처";
        item.serviceTy_label = "서비스유형";
      });
      return items;
    },
    clearInput() {
      this.searchValue = ""; // 입력값 초기화
    },
    prjReset() {
      const prjClear = { prjId: "", prjNm: "" };
      this.prjSelected(prjClear);
      this.clearInput();
    },
    loadUserData() {
      const user = JSON.parse(localStorage.getItem("User"));
      if (user) {
        this.userRole = user.userRole;
        this.fetchMembers(user.username);
      }
      //alert("this.userRole. "+this.userRole);
    },
    fetchMembers(userId = "") {
      api
        .get(`/member?userId=${userId}`)
        .then((response) => {
          const userData = response.data[0];
          this.userRole = userData?.userRole || "";
          this.userId = userData?.userId || "";
          this.fetchProjects(this.currentPage);
        })
        .catch((error) => {
          console.error("회원 정보를 불러오는 중 오류가 발생했습니다.", error);
        });
    },
  },
  computed: {
    filteredItems() {
      return this.projects; // Assuming Items is the array of projects
    },
  },
  mounted() {
    this.loadUserData();
    //this.fetchProjects(this.currentPage);
  },
};
</script>
