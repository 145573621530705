<template>
  <router-link to="/" @click.native="resetMenu">
    <img class="img-fluid for-light" src="../../assets/images/logo/logo.png" alt="" />
    <img class="img-fluid for-dark" src="../../assets/images/logo/logo_dark.png" alt="" />
  </router-link>
</template>
<style scoped>
.img-fluid {
  max-width: 196px !important;
}
</style>
<script>
export default {
  name: "Logo",
  methods: {
    resetMenu() {
      this.$store.commit("menu/resetMenuState"); // Vuex의 resetMenuState 호출
    },
  },
};
</script>
