export function validateInput(value, message, swal) {
  if (!value) {
    swal({
      icon: "warning",
      text: message,
    });
    return false;
  }
  return true;
}

export function dataException(message, swal) {
  swal({
    icon: "error",
    text: message,
  });
  return false;
}

export function removeHyphens(phone) {
  return phone.replace(/-/g, "");
}

export function removegPhoneHyphens(gPhone) {
  return gPhone.replace(/-/g, "");
}

export function formatAreas(areas) {
  if (areas.length == 0) return "";
  if (areas.length == 1) return areas[0].areaNm;
  if (areas.length > 1) return `${areas[0].areaNm} 외 ${areas.length - 1}개`;
}

export function formatCareUsers(careUsers) {
  if (careUsers.length == 0) return "";
  if (careUsers.length == 1) return careUsers[0].careUserNm;
  if (careUsers.length > 1) return `${careUsers[0].careUserNm} 외 ${careUsers.length - 1}명`;
}

export function formatRobotStatus(robotStatus) {
  if (robotStatus == "on" || robotStatus == "active" || robotStatus == "charge" || robotStatus == "completed") return "사용중";
  else if (robotStatus == "none" || robotStatus == "off") return "미사용";
  else if (robotStatus == "error") return "고장";
}

export function formatInputPhone(phone) {
  return phone.replace(/[^0-9]/g, "");
}

export function formatInputgPhone(gPhone) {
  return gPhone.replace(/[^0-9]/g, "");
}

export function validateEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function formatPhoneNumber(phone) {
  if (phone == undefined) return;
  // 입력 필드에서 포커스를 잃었을 때 전화번호 형식을 적용합니다.
  let numbers = phone.replace(/\D/g, "");
  if (phone.length == 9) {
    return numbers.replace(/(\d{2})(\d{3})(\d{4})/, "$1-$2-$3");
  } else if (phone.length == 11) {
    return numbers.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
  } else if (phone.length == 10) {
    return numbers.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  }
  return numbers;
}

export function formatgPhoneNumber(gPhone) {
  let numbers = gPhone.replace(/\D/g, "");
  // 입력 필드에서 포커스를 잃었을 때 전화번호 형식을 적용합니다.
  return numbers.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
}

export function formatGender(gender) {
  return gender === "m" ? "남성" : "여성";
}

export function formatUserDelYn(delYn) {
  return delYn === "Y" ? "탈퇴" : "이용중";
}

export function formatPrjDelYn(delYn) {
  return delYn === "Y" ? "삭제" : "정상";
}

export function formatPhone(phone) {
  return `${phone.slice(0, 3)}-${phone.slice(3, 7)}-${phone.slice(7, 11)}`;
}

export function formatRole(userRole) {
  const roleMap = {
    t: "임시",
    0: "관리자",
    // '1': '운영자',
    2: "기관 관리자",
    3: "돌봄자",
  };
  return roleMap[userRole] || "unkown"; // 일치하는 역할이 없는 경우 '알 수 없음' 반환
}

export function getLoginId() {
  const user = JSON.parse(localStorage.getItem("User"));
  const loginId = user.username;
  return loginId;
}
export function getLoginUserRole() {
  const user = JSON.parse(localStorage.getItem("User"));
  const userRole = user.userRole;
  return userRole;
}
export function getLoginPrjId() {
  const user = JSON.parse(localStorage.getItem("User"));
  const prjId = user.prjId;
  return prjId;
}
export function getLoginPrjNm() {
  const user = JSON.parse(localStorage.getItem("User"));
  const prjNm = user.prjNm;
  return prjNm;
}
export function getLoginServiceTy() {
  const user = JSON.parse(localStorage.getItem("User"));
  const serviceTy = user.serviceTy;
  return serviceTy;
}

export function formatRobot(robotType) {
  const robotTyMap = {
    movement: "이동",
    excretion: "배설",
    bath: "목욕",
    wearable: "유연착용형",
    lifting: "이승",
    posture: "욕창예방 및 자세변환",
    feeding: "식사",
    communication: "소통",
    aibot: "AI",
    monitoringWearable: "모니터링(생체정보)",
    monitoringSensor: "모니터링(낙상감지)",
    monitoringRobot: "모니터링(배회감지)",
  };
  return robotTyMap[robotType] || "-";
}

export function formatSleepStatus(sleepStatus) {
  const sleeTyMap = {
    1: "깨어남(수면 주기 중)",
    2: "절전 모드",
    3: "침대 외",
    4: "얕은 수면",
    5: "깊은 수면",
    6: "렘 수면",
  };
  return sleeTyMap[sleepStatus] || "";
}

export function formatImportance(importance) {
  const importanceMap = {
    1: "보통",
    2: "중요",
    3: "긴급",
  };
  return importanceMap[importance] || "-";
}

export function formatNoticeScope(noticeScope) {
  const noticeScoprMap = {
    prj: "기관",
    all: "전체",
  };
  return noticeScoprMap[noticeScope] || "-";
}

export function formatServiceTy(serviceTy) {
  const serviceTyMap = {
    1: "시설",
    2: "재가",
    3: "공통",
  };
  return serviceTyMap[serviceTy] || "-";
}

export function stringListToNumberList(stringList) {
  return stringList.map((str) => {
    const num = parseFloat(str);
    return isNaN(num) ? 0 : num; // 변환 실패 시 기본값 0을 사용
  });
}

export function formatGRelation(gGelation) {
  const gGelationMap = {
    1: "배우자",
    2: "자녀",
    3: "형제",
    4: "친척",
    5: `기타`,
  };
  return gGelationMap[gGelation] || "";
}

import api from "@/api.js";

export async function getPrjData(prjId) {
  let prj = "";
  const url = `/project?prjId=${prjId}`;
  await api
    .get(url)
    .then((response) => {
      // console.log("response.data.getPrjData : ", response.data);
      prj = response.data.length > 0 ? response.data[0] : "";
    })
    .catch((error) => {
      console.error("기관 정보를 불러오는 중 오류가 발생했습니다.", error);
    });
  return prj;
}
export async function getPrjNm(prjId) {
  let prjNm = "";
  const url = `/project?prjId=${prjId}`;
  await api
    .get(url)
    .then((response) => {
      const prj = response.data.length > 0 ? response.data[0] : "";
      prjNm = prj.prjNm;
    })
    .catch((error) => {
      console.error("기관 정보를 불러오는 중 오류가 발생했습니다.", error);
    });
  return prjNm;
}

export async function getUserData(userId) {
  let user = "";
  const url = `/member?userId=${userId}`;
  await api
    .get(url)
    .then((response) => {
      // console.log("response.data.getUserData : ", response.data);
      user = response.data.length > 0 ? response.data[0] : "";
    })
    .catch((error) => {
      console.error("사용자 정보를 불러오는 중 오류가 발생했습니다.", error);
    });
  return user;
}

//y.mo add span ramdom color
export function getRandomBadgeClass() {
  const badgeClasses = [
    /* 'badge badge-primary',
    'badge badge-secondary',
    'badge badge-success',
    'badge badge-danger',
    'badge badge-warning',
    'badge badge-info', */
    "badge badge-light text-dark",
  ];
  const randomIndex = Math.floor(Math.random() * badgeClasses.length);
  return badgeClasses[randomIndex];
}
