
export const defaultLocale = 'kr';
export const localeOptions=[
    { id:'kr',name:'한국어',icon:'flag-icon-kr' },
    /*{ id:'en',name:'English',icon:'flag-icon-us', short:'(US)'},
      { id:'es',name:'Español',icon:'flag-icon-es' },
    { id:'fr',name:'Français',icon:'flag-icon-fr' },
    { id:'pt',name:'Português',icon:'flag-icon-pt',short:'(BR)'}, */
    
];
