export function formatStringDate(date) {
  return `${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6, 8)}`;
}

export function formatTime(datetime) {
  const date = new Date(datetime);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}`;
}
export function formatTimeNotHyphen(datetime) {
  const localDatetime = datetime.replace("Z", "");
  const date = new Date(localDatetime);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}`;
}
export function formatOnlyTime(datetime) {
  const date = new Date(datetime);
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${hours}:${minutes}`;
}

export const formatDate = (date) => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};
export const formatDateMonth = (date) => {
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${year}-${month}`;
};
export const formatDateWeek = (date) => {
  return `${formatDate(date[0])}~ ${formatDate(date[1])}`;
};

export const formatDateNotHyphen = (date) => {
  if (!date || !(date instanceof Date)) return "";
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${year}${month}${day}`;
};

export const calTimeDiff = (start, end) => {
  const diffmsec = end - start;
  const diffmin = diffmsec / (60 * 1000);
  const hour = Math.floor(diffmin / 60);
  const minute = diffmin % 60;
  return `${hour}시간 ${minute}분`;
};
export const calStrTimeDiff = (start, end) => {
  const sDate = parseTime(start);
  const eDate = parseTime(end);

  return calTimeDiff(sDate, eDate);
};

function parseTime(timeStr) {
  const [hours, minutes] = timeStr.split(":").map(Number);
  const date = new Date();
  date.setHours(hours, minutes, 0, 0);
  return date;
}
export function getAge(birthDt) {
  const birthdate = new Date(birthDt);
  const now = new Date();

  let age = now.getFullYear() - birthdate.getFullYear();
  if (now.getMonth() < birthdate.getMonth() || (now.getMonth() === birthdate.getMonth() && now.getDate() < birthdate.getDate())) {
    age--;
  }

  return age;
}
