<template>
  <div class="col-xxl-12 col-md-12 box-col-8" v-if="userRole == '3' && serviceTy == '2'" style="display: none"></div>

  <div v-else class="col-xxl-12 col-md-12 box-col-8">
    <div class="row">
      <div v-if="!targetList || targetList.length === 0" class="col-12">
        <div class="card small-widget balance-widget mt-3">
          <div class="empty-state card-body primary text-center" style="align-content: center">
            <h5>조회된 내역이 없습니다</h5>
          </div>
        </div>
      </div>
      <div class="main-custom-col" v-for="(item, index) in targetList" :key="index">
        <br />

        <div class="card small-widget balance-widget" @click="careGiverCard(item.careUserId, serviceTy, item.areaId)">
          <div class="card-body primary">
            <div class="header-top mb-3" v-if="serviceTy == '2' && (userRole == '0' || userRole == '2')">
              <span class="f-light"
                ><b>{{ item.careUserNm }} </b></span
              >
            </div>
            <div class="header-top mb-3" v-if="serviceTy == '1' && userRole == '0'">
              <span class="f-light"
                ><b>{{ item.areaNm }} </b></span
              >
            </div>

            <div class="header-top mb-3" v-if="serviceTy == '1' && userRole == '2'">
              <span class="f-light"
                ><b>{{ item.areaNm }} </b></span
              >
            </div>
            <div class="header-top mb-3" v-if="serviceTy == '2' && userRole == '2'">
              <span class="f-light"
                ><b>{{ item.userNm }} </b></span
              >
            </div>

            <div class="header-top mb-3" v-if="serviceTy == '1' && userRole == '3'">
              <span class="f-light"
                ><b>{{ item.areaNm }}</b></span
              >
            </div>
            <div class="header-top mb-3" v-if="serviceTy == '2' && userRole == '3'">
              <span class="f-light"
                ><b>{{ item.userNm }}</b></span
              >
            </div>
            <div class="d-flex align-items-end gap-1">
              <div class="row">
                <div style="margin-left: 10px" class="col-12 list-group-item">
                  <h5>
                    전체 : <font class="font-success">{{ item.total }}</font>
                  </h5>
                </div>
                <div style="margin-left: 10px" class="col-12 col-md-12 col-lg-12 list-group-item">
                  <h8>위험 : </h8><b class="font-secondary">{{ item.danger }}</b>
                </div>
                <div style="margin-left: 10px" class="col-5 col-md-4 col-lg-6 list-group-item">
                  <h8>주의 </h8><br /><b class="font-warning">{{ item.warning }}</b>
                </div>
                <div class="col-6 col-md-4 list-group-item">
                  <h8>정상 </h8><br /><b class="font-primary">{{ item.normal }}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.large-widget {
  width: 100%;
  min-height: 100vh; /* Viewport의 전체 높이로 설정 */
  margin: 50px auto;
  padding: 50px 20px;
  box-sizing: border-box; /* 패딩 포함해서 height 계산 */
}

.large-widget .card-body {
  height: 100%;
  font-size: 1.5rem;
  display: flex; /* Flexbox 사용 */
  align-items: center; /* 수직 중앙 정렬 */
  justify-content: center; /* 수평 중앙 정렬 */
}
@media (min-width: 1797px) {
  .main-custom-col {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 1201px) and (max-width: 1796px) {
  .main-custom-col {
    flex: 0 0 20.66666667%;
    width: 20.66666667%;
  }
}

@media (min-width: 688px) and (max-width: 1200px) {
  .main-custom-col {
    flex: 0 0 25%;
    width: 25%;
  }
}

@media (max-width: 687px) {
  .main-custom-col {
    flex: 0 0 50%;
    width: 50%;
  }
}
</style>

<script>
import DropDown1 from "../base/dropdown/DropDown1.vue";
import api from "@/api.js";
import * as criteria from "@/data/criteriaData.js";
import { ref, computed } from "vue";
import targetListGroupVue from "../targetListGroup.vue";
const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
export default {
  components: {
    DropDown1,
  },
  props: {
    prjId: {
      type: String,
      required: true,
    },
    serviceTy: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      targetList: [],
      prjNm: "",
    };
  },
  mounted() {
    let user = JSON.parse(localStorage.getItem("User"));
    let serviceTy = user.serviceTy;
    let userRole = user.userRole;
    // this.serviceTy = serviceTy;
    this.userRole = userRole;

    if (this.userRole == "2") this.fetchTargets(this.serviceTy, user.prjId, this.userRole);
  },

  methods: {
    fetchTargets(serviceTy = "", prjId = "", userRole = "", prjNm = "") {
      // this.serviceTy = serviceTy;
      const url = `/target/cnt?serviceTy=${serviceTy}&prjId=${prjId}&userRole=${userRole}&prjNm=${prjNm}`;

      api
        .get(url)
        .then((response) => {
          this.targetList = response.data.cntList;
        })
        .catch((error) => {
          console.error("타켓 불러오는 중 오류가 발생했습니다.", error);
          this.$swal({
            icon: "error",
            title: "타겟 목록을 불러오는 중 오류가 발생했습니다.",
            text: error,
            type: "error",
          });
        });
    },
    careGiverCard(careUserId, serviceTy, areaId) {
      let userId = "";
      let prjId = this.prjId;

      if (serviceTy == "2") {
        this.$router.push({ name: "targetListCareGroup", params: { userId: careUserId, prjId: prjId } });
      } else {
        this.$router.push({ name: "targetListCard", params: { areaId: areaId, serviceTy: serviceTy } });
      }
    },
  },

  setup() {},
};
</script>
