export var menuItems = {
  data: [
    {
      headTitle1: "General",
      type: "headtitle",
      roles: ["0", "1", "2", "3"],
    },
    {
      title: "Dashboard",
      icon: "stroke-home",
      iconf: "fill-home",
      type: "link",
      active: false,
      path: "/",
      roles: ["0", "1", "2", "3"],
    },
    {
      title: "현황",
      icon: "stroke-charts",
      iconf: "fill-charts",
      type: "sub",
      active: false,
      roles: ["0", "1", "2", "3"],
      children: [
        {
          path: "/monitoring/projectMonitor",
          title: "기관현황",
          type: "link",
          active: false,
          roles: ["0", "1", "2"],
        },
        {
          path: "/monitoring/caregiverMonitor",
          title: "돌봄자현황",
          type: "link",
          active: false,
          roles: ["0", "1", "2"],
        },
        {
          path: "/monitoring/targetMonitor",
          title: "돌봄받는자현황",
          type: "link",
          active: false,
          roles: ["0", "1", "2", "3"],
        },
        {
          path: "/monitoring/robotMonitor",
          title: "로봇현황",
          type: "link",
          active: false,
          roles: ["0", "1", "2"],
        },
      ],
    },
    {
      headTitle1: "Works",
      type: "headtitle",
      roles: ["0", "1", "2", "3"],
    },
    {
      title: "돌봄일정",
      icon: "stroke-table",
      iconf: "fill-table",
      type: "link",
      active: false,
      path: "/works/careSchedule",
      roles: ["0", "1", "3"],
    },
    {
      title: "건강관리기록",
      icon: "stroke-editors",
      iconf: "fill-editors",
      type: "link",
      active: false,
      path: "/works/healthRecord",
      roles: ["0", "1", "2", "3"],
    },
    {
      headTitle1: "Management",
      type: "headtitle",
      roles: ["0", "1", "2", "3"],
    },
    {
      title: "기관",
      icon: "stroke-project",
      iconf: "fill-project",
      type: "sub",
      active: false,
      roles: ["0", "1", "2", "3"],
      children: [
        {
          path: "/projects/projectList",
          title: "기관관리",
          type: "link",
          active: false,
          roles: ["0", "1"],
        },
        {
          path: "/careGiver/careGiverList",
          title: "돌봄자관리",
          type: "link",
          active: false,
          roles: ["0", "1", "2"],
        },
        {
          path: "/targets/targetList",
          title: "돌봄받는자관리",
          type: "link",
          active: false,
          roles: ["0", "1", "2", "3"],
        },
      ],
    },
    {
      title: "돌봄로봇",
      icon: "stroke-ui-kits",
      iconf: "fill-ui-kits",
      type: "link",
      active: false,
      path: "/robots/robotList",
      roles: ["0", "1"],
    },
    {
      title: "사용자",
      icon: "stroke-user",
      iconf: "fill-user",
      type: "sub",
      active: false,
      roles: ["0", "1", "2", "3", "t"],
      children: [
        {
          path: "/users/userList",
          title: "사용자관리",
          type: "link",
          active: false,
          roles: ["0", "1"],
        },
        {
          path: "/users/profile",
          title: "내정보",
          type: "link",
          active: false,
        },
      ],
    },
    {
      headTitle1: "Notice",
      type: "headtitle",
    },
    {
      title: "공지사항",
      icon: "stroke-editors",
      iconf: "fill-editors",
      type: "link",
      active: false,
      path: "/notice/noticeList",
    },
  ],
};
