const state = {
    userData: {},
  };
  
  const mutations = {
    SET_USER_DATA(state, data) {
      state.userData = data;
    },
  };
  
  const actions = {
    updateUserData({ commit }, data) {
      commit('SET_USER_DATA', data);
    },
  };
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
  };
  