<template>
  <div>
    <br />
  </div>
</template>

<script>
import api from "@/api.js";
import { ref, computed } from "vue";
import { formatTime, formatDate, formatDateNotHyphen } from "@/utils/dateUtils.js";
import { noticeListHeader } from "@/data/tableHeaders.js";
import { getLoginId, formatImportance, getLoginUserRole } from "@/utils/common";

export default {
  data() {
    return {
      // itemHeight: 40, // 각 항목의 높이
      scrollDuration: 1000, // 스크롤 지속 시간 (밀리초)
      scrollInterval: null,

      formatDate,
      formatTime,
      formatImportance,
      formatDateNotHyphen,
      Headers: noticeListHeader,
      Items: [],
    };
  },
  mounted() {
    this.fetchNotice();
  },
  setup() {
    const today = new Date();
    const noticeStart = ref(new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()));
    const noticeEnd = ref(new Date());

    return { noticeStart, noticeEnd };
  },

  methods: {
    fetchNotice() {
      const url = `/notice?noticeStart=${formatDateNotHyphen(this.noticeStart)}&noticeEnd=${formatDateNotHyphen(this.noticeEnd)}`;
      api
        .get(url)
        .then((response) => {
          this.Items = response.data;
        })
        .catch((error) => {
          console.error("공지사항 목록을 불러오는 중 오류가 발생했습니다.", error);
          this.$swal({
            icon: "error",
            title: "공지사항 목록을 불러오는 중 오류가 발생했습니다.",
            text: error,
            type: "error",
          });
        });
    },
  },
};
</script>

<style scoped>
.scroll-container {
  position: relative;
  height: 40px; /* 컨테이너 높이 설정 (하나의 항목 높이와 동일) */
  overflow: hidden;
}

.scroll-content {
  display: flex;
  flex-direction: column;
  animation: scroll linear infinite;
  animation-duration: 10s; /* 스크롤 전체 지속 시간 */
}

.scroll-item {
  height: 40px; /* 각 항목의 높이 설정 */
  line-height: 40px;
  text-align: center;
  box-sizing: border-box;
}

/* 애니메이션 정의 */
@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
</style>
