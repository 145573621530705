<template>
  <div class="col-xxl-12 col-md-12 box-col-8">
    <div class="row">
      <div class="col-xl-4 col-md-12 col-sm-12">
        <div class="card o-hidden">
          <div class="card-body balance-widget">
            <div class="row main-status-cnt-card">
              <!-- style="height: 85px" -->
              <div class="col-xl-4 col-md-3">
                <span class="f-w-500 f-light">총 돌봄받는자</span>
                <h5 class="font-success mb-0 mt-1 f-w-500 mb-0 f-30">
                  <span class="counter">{{ allCareCntList ? allCareCntList.total : 0 }}</span>
                </h5>
              </div>
              <div class="col-xl-3 col-md-3">
                <span class="f-w-500 f-light">위험</span>
                <h5 class="font-secondary mb-0 mt-1 f-w-500 mb-0 f-30">
                  <span class="counter">{{ allCareCntList ? allCareCntList.danger : 0 }}</span>
                </h5>
              </div>
              <div class="col-xl-3 col-md-3">
                <span class="f-w-500 f-light">주의</span>
                <h5 class="font-warning mb-0 mt-1 f-w-500 mb-0 f-30">
                  <span class="counter">{{ allCareCntList ? allCareCntList.warning : 0 }}</span>
                </h5>
              </div>
              <div class="col-xl-2 col-md-3">
                <span class="f-w-500 f-light">정상</span>
                <h5 class="font-primary mb-0 mt-1 f-w-500 mb-0 f-30">
                  <span class="counter">{{ allCareCntList ? allCareCntList.normal : 0 }}</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xxl-2 col-xl-3 col-md-6">
        <div class="card small-widget balance-widget">
          <div class="card-body secondary">
            <span class="f-light"><b>낙상감지 / 침대이탈</b></span>
            <div class="d-flex align-items-end gap-1">
              <h1>{{ robotTypeCnt.fallDetection || 0 }}</h1>
            </div>
            <div class="bg-gradient">
              <svg class="stroke-icon svg-fill">
                <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + '#detection'"></use>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xxl-2 col-xl-3 col-md-6">
        <div class="card small-widget balance-widget">
          <div class="card-body primary">
            <span class="f-light"><b>야간 배회</b> </span>
            <div class="d-flex align-items-end gap-1">
              <h1>{{ robotTypeCnt.nightWander || 0 }}</h1>
            </div>
            <div class="bg-gradient">
              <svg class="stroke-icon svg-fill">
                <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + '#wandering'"></use>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xxl-2 col-xl-3 col-md-6">
        <div class="card small-widget balance-widget">
          <div class="card-body warning">
            <span class="f-light"><b>외부 이탈</b> </span>
            <div class="d-flex align-items-end gap-1">
              <h1>{{ robotTypeCnt.breakAway || 0 }}</h1>
            </div>
            <div class="bg-gradient">
              <svg class="stroke-icon svg-fill">
                <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + '#departure'"></use>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xxl-2 col-xl-3 col-md-6">
        <div class="card small-widget balance-widget">
          <div class="card-body success">
            <!-- primary, secondary, success, warning -->
            <span class="f-light"><b>로봇 특이사항</b> </span>
            <div class="d-flex align-items-end gap-1">
              <h1>{{ robotTypeCnt.note || 0 }}</h1>
            </div>
            <div class="bg-gradient">
              <svg class="stroke-icon svg-fill">
                <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + '#robotfeatures'"></use>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-6" v-for="allDetail in allDetails" :key="allDetail">
            <div class="card small-widget">
                <div :class="allDetail.cardClass">
                    <span class="f-light">{{ allDetail.title }}</span>
                    <div class="d-flex align-items-end gap-1">
                        <h4>{{ allDetail.dataInNumber }}</h4>
                        <span :class="allDetail.spanClass"><i :class="allDetail.iconClass"></i><span>{{ allDetail.status }}</span></span>
                    </div>
                    <div class="bg-gradient">
                        <svg class="stroke-icon svg-fill">
                            <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#${allDetail.svgIcon}`"></use>
                        </svg>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
  </div>
</template>
<style>
@media (min-width: 1025px) {
  .main-status-cnt-card {
    height: 84px;
  }
}
</style>
<script>
import api from "@/api.js";
import * as criteria from "@/data/criteriaData.js";
import { ref, computed } from "vue";
const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
import { getLoginUserRole, getLoginPrjId, getLoginId } from "@/utils/common.js";
import { formatDateNotHyphen } from "@/utils/dateUtils.js";
export default {
  props: {
    areaId: {
      type: String,
      required: true,
    },
    prjId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      allCareCntList: [],
      robotTypeCnt: [],
      userRole: getLoginUserRole(),
      getLoginId,
    };
  },
  methods: {
    fetchTargets(serviceTy = "", prjId = "", areaId = "") {
      let userId = "";
      if (this.userRole == "3") userId = getLoginId();
      const url = `/target/cnt?serviceTy=${serviceTy}&prjId=${prjId}&areaId=${areaId}&userId=${userId}`;
      api
        .get(url)
        .then((response) => {
          this.allCareCntList = response.data?.summary ?? 0;
        })
        .catch((error) => {
          console.error("총 돌봄받는 자 수 오류가 발생했습니다.", error);
          this.$swal({
            icon: "error",
            title: "총 돌봄받는 자 수 오류가 발생했습니다.",
            text: error,
            type: "error",
          });
        });
    },
    fetchRobotTypecnt(prjId = "") {
      const url = `/robot/monitoring/cnt?prjId=${prjId}`;
      api
        .get(url)
        .then((response) => {
          this.robotTypeCnt = response.data ?? {};
        })
        .catch((error) => {
          console.error("로봇 종류 카운트 수 오류가 발생했습니다.", error);
          this.$swal({
            icon: "error",
            title: "로봇 종류 카운트 수 오류가 발생했습니다.",
            text: error,
            type: "error",
          });
        });
    },
    fetchWeird() {
      const userRole = this.userRole;
      const prjId = this.prjId;
      const url = `/target/monitoring?userRole=${this.userRole}&dt=${formatDateNotHyphen(this.dt)}`;
      api
        .get(url)
        .then((response) => {
          let abnormalCount = response.data.filter((item) => {
            const pulse = item.data?.pulse;
            const bodyTemperature = item.data?.bodyTemperature;
            const oxygenSaturation = item.data?.oxygenSaturation;

            return (
              (pulse !== undefined && pulse < 50) ||
              pulse > 120 ||
              (bodyTemperature !== undefined && bodyTemperature < 35.5) ||
              bodyTemperature > 38.0 ||
              (oxygenSaturation !== undefined && oxygenSaturation < 90)
            );
          }).length;

          let abWarningCount = response.data.filter((item) => {
            const pulse = item.data?.pulse;
            const bodyTemperature = item.data?.bodyTemperature;
            const oxygenSaturation = item.data?.oxygenSaturation;

            return (
              (pulse !== undefined && pulse >= 50 && pulse <= 59) ||
              (pulse >= 101 && pulse <= 120) ||
              (bodyTemperature !== undefined && bodyTemperature >= 35.5 && bodyTemperature <= 36.4) ||
              (bodyTemperature >= 37.6 && bodyTemperature <= 38.0) ||
              (oxygenSaturation !== undefined && oxygenSaturation >= 90 && oxygenSaturation <= 94)
            );
          }).length;

          let abnormalSuccesCount = response.data.filter((item) => {
            const pulse = item.data?.pulse;
            const bodyTemperature = item.data?.bodyTemperature;
            const oxygenSaturation = item.data?.oxygenSaturation;

            return (
              (pulse !== undefined && pulse >= 60 && pulse <= 100) ||
              (bodyTemperature !== undefined && bodyTemperature >= 36.5 && bodyTemperature <= 37.5) ||
              (oxygenSaturation !== undefined && oxygenSaturation >= 95 && oxygenSaturation <= 100)
            );
          }).length;

          // 결과를 출력하거나 저장

          this.abnormalCareTargetsCount = abnormalCount;
          this.abnormalCareTargetsWaringCount = abWarningCount;
          this.abnormalCareTargetsSuccessCount = abnormalSuccesCount;
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "이상 인 수를 불러오는 중 오류가 발생했습니다.",
            text: error,
            type: "error",
          });
        });
    },
  },
  mounted() {
    // this.fetchTargets(this.prjId, this.serviceTy, this.areaId, userInfo.username);
    this.fetchWeird();
    this.fetchRobotTypecnt();
  },
  setup() {
    const dt = ref(new Date());
    return { dt };
  },
};
</script>
