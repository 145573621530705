export const genderOptions = [
  { value: "m", label: "남성" },
  { value: "f", label: "여성" },
];

export const roleOptions = [
  { value: "0", label: "관리자" },
  { value: "2", label: "기관 관리자" },
  { value: "3", label: "돌봄자" },
];

export const delYnOptions = [
  { value: "", label: "-" },
  { value: "Y", label: "탈퇴" },
];

export const genderAllOptions = [
  { value: "m", label: "남성" },
  { value: "f", label: "여성" },
  { value: "all", label: "전체" },
];

export const roleAllOptions = [
  { value: "t", label: "임시" },
  { value: "0", label: "관리자" },
  { value: "2", label: "기관 관리자" },
  { value: "3", label: "돌봄자" },
  { value: "all", label: "전체" },
];

export const delYnAllOptions = [
  { value: "", label: "-" },
  { value: "Y", label: "탈퇴" },
  { value: "all", label: "전체" },
];

export const robotOptions = [
  { value: "", label: "- 선택 -" },
  { value: "movement", label: "이동" },
  { value: "excretion", label: "배설" },
  { value: "bath", label: "목욕" },
  { value: "wearable", label: "유연착용형" },
  { value: "monitoringWearable", label: "모니터링(생체정보)" },
  { value: "monitoringSensor", label: "모니터링(낙상감지)" },
  { value: "monitoringRobot", label: "모니터링(배회감지)" },
  { value: "lifting", label: "이승" },
  { value: "posture", label: "욕창예방 및 자세변환" },
  { value: "feeding", label: "식사" },
  { value: "communication", label: "소통" },
  { value: "aibot", label: "AI" },
];

export const robotAllOptions = [
  { value: "movement", label: "이동" },
  { value: "excretion", label: "배설" },
  { value: "bath", label: "목욕" },
  { value: "wearable", label: "유연착용형" },
  { value: "monitoringWearable", label: "모니터링(생체정보)" },
  { value: "monitoringSensor", label: "모니터링(낙상감지)" },
  { value: "monitoringRobot", label: "모니터링(배회감지)" },
  { value: "lifting", label: "이승" },
  { value: "posture", label: "욕창예방 및 자세변환" },
  { value: "feeding", label: "식사" },
  { value: "communication", label: "소통" },
  { value: "aibot", label: "AI" },
  { value: "all", label: "전체" },
];

export const roboatStatusAllOptions = [
  { value: "use", label: "사용중" },
  { value: "error", label: "고장" },
  { value: "notUse", label: "미사용" },
  { value: "all", label: "전체" },
];

export const targetStateAllOptions = [
  { value: "Y", label: "승인완료" },
  { value: "N", label: "승인대기" },
  { value: "all", label: "전체" },
];
export const prjDelYnAllOptions = [
  { value: "N", label: "정상" },
  { value: "Y", label: "삭제" },
  { value: "all", label: "전체" },
];

export const gradeOptions = [
  { value: "1", label: "1등급" },
  { value: "2", label: "2등급" },
  { value: "3", label: "3등급" },
  { value: "4", label: "4등급" },
];
export const relatioinOptions = [
  { value: "1", label: "배우자" },
  { value: "2", label: "자녀" },
  { value: "3", label: "형제" },
  { value: "4", label: "친척" },
  { value: "5", label: "기타" },
];

export const twoRelatioinOptions = [
  { value: "1", label: "배우자" },
  { value: "2", label: "자녀" },
  { value: "3", label: "형제" },
  { value: "4", label: "친척" },
  { value: "5", label: "기타" },
];

export const prjServiceTyOptions = [
  { value: "1", label: "시설" },
  { value: "2", label: "재가" },
  { value: "3", label: "공통" },
];

export const careTargetServiceTyOptions = [
  { value: "m", label: "시설" },
  { value: "f", label: "재가" },
];

import api from "@/api.js";

export async function getAreaData(prjId) {
  const url = `/project/areas?prjId=${prjId}`;
  const areaOptions = [];
  await api
    .get(url)
    .then((response) => {
      // console.log("response.data.area : ", response.data);
      if (response.data.length == 0) return;
      const areas = response.data;
      const parentsIdMap = Map.groupBy(areas, ({ parentsId }) => parentsId);
      parentsIdMap.get("0").forEach((area) => {
        const parentsObj = {};
        const children = parentsIdMap.get(area.areaId);
        const childrenObj = makeAreaChildrenObj(children, area.areaNm);

        parentsObj["id"] = area.areaId;
        parentsObj["label"] = area.areaNm;
        parentsObj["depth"] = 0;
        parentsObj["children"] = childrenObj;
        areaOptions.push(parentsObj);
      });
    })
    .catch((error) => {
      console.error("구역 정보를 불러오는 중 오류가 발생했습니다.", error);
    });
  return areaOptions;
}
export function makeAreaChildrenObj(children, parentsLabel) {
  const childrenObj = [];

  if (children != null) {
    children.map((child) => {
      let childObj = {};
      childObj["id"] = child.areaId;
      childObj["label"] = parentsLabel + " " + child.areaNm;
      childObj["delth"] = 1;
      childObj["children"] = [];
      childrenObj.push(childObj);
    });
  }
  return childrenObj;
}

export async function getCodeData(codeGroup) {
  const url = `/schedule/code?codeGroup=${codeGroup}`;
  const codeOptions = [];
  await api
    .get(url)
    .then((response) => {
      // console.log("response.data.codes : ", response.data);
      const codes = response.data;
      const parentsIdMap = Map.groupBy(codes, ({ parentsId }) => parentsId);
      parentsIdMap.get("0").forEach((code) => {
        const parentsObj = {};
        const children = parentsIdMap.get(code.codeId);
        const childrenObj = makeCodeChildrenObj(children, code.codeNm);

        parentsObj["id"] = code.codeId;
        parentsObj["label"] = code.codeNm;
        parentsObj["depth"] = 0;
        parentsObj["children"] = childrenObj;
        codeOptions.push(parentsObj);
      });
    })
    .catch((error) => {
      console.error("코드 정보를 불러오는 중 오류가 발생했습니다.", error);
    });
  return codeOptions;
}
export function makeCodeChildrenObj(children, parentsLabel) {
  const childrenObj = [];

  if (children != null) {
    children.map((child) => {
      let childObj = {};
      childObj["id"] = child.codeId;
      childObj["label"] = child.codeNm;
      childObj["delth"] = 1;
      childObj["children"] = [];
      childrenObj.push(childObj);
    });
  }
  return childrenObj;
}

export async function getCodeMap(codeGroup) {
  const url = `/schedule/code?codeGroup=${codeGroup}`;
  let codeMap = {};
  await api
    .get(url)
    .then((response) => {
      const codes = response.data;
      codes.forEach((code) => {
        codeMap[code.codeId] = code.codeNm;
      });
    })
    .catch((error) => {
      console.error("코드 정보를 불러오는 중 오류가 발생했습니다.", error);
    });
  return codeMap;
}
