<template>
  <li class="profile-nav onhover-dropdown pe-0 py-0">
    <div class="media profile-media">
      <img class="b-r-10" src="@/assets/images/user/user.png" style="width: 35px" alt="" />
      <!-- src="@/assets/images/dashboard/profile.png" -->
      <div class="media-body">
        <span>{{ userNm }}</span>
        <p class="mb-0 font-roboto">{{ userRole }} <i class="middle fa fa-angle-down"></i></p>
      </div>
    </div>
    <ul class="profile-dropdown onhover-show-div">
      <li @click="profile"><vue-feather type="user"></vue-feather><span>Account </span></li>

      <li @click="logout"><vue-feather type="log-in"></vue-feather><span>Log out</span></li>
    </ul>
  </li>
</template>

<script>
// import firebase from 'firebase';
// import UserAuth from '../../auth/js/index';
import api from "@/api.js";
const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;

export default {
  name: "Profile",
  data() {
    return {
      userId: "",
      userNm: "",
      userRole: "",
    };
  },
  mounted() {
    var user = JSON.parse(localStorage.getItem("User"));
    var username = user.username;
    this.userId = username;
    this.fetchMembers(this.userId);
  },
  methods: {
    fetchMembers(userId = "") {
      const url = `/member?userId=${userId}`;
      api
        .get(url)
        .then((response) => {
          console.log("response.data : ", response.data);

          //this.member = response.data; // 회원 데이터 할당
          // 첫 번째 항목에서 전체 회원 수(totalCnt)를 가져오되, 데이터가 없는 경우 0으로 처리
          this.userNm = response.data[0]?.userNm || "";
          this.userRole = response.data[0]?.userRole || "";
          // 전체 페이지 수 계산 및 현재 페이지 설정
          //this.totalPages = Math.ceil(totalMembers / this.pageSize);
          // this.currentPage = page; // 현재 페이지 업데이트
          this.userRole = this.formatRole(this.userRole);
        })
        .catch((error) => {
          console.error("회원 정보를 불러오는 중 오류가 발생했습니다.", error);
        });
    },
    formatRole(userRole) {
      const roleMap = {
        t: "임시",
        0: "관리자",
        2: "기관 관리자",
        3: "돌봄자",
      };
      return roleMap[userRole] || "unkown"; // 일치하는 역할이 없는 경우 '알 수 없음' 반환
    },
    logout: function () {
      // firebase
      //   .auth()
      //   .signOut()
      //   .then(() => {
      //     UserAuth.Logout();
      // 메뉴 상태 초기화
      this.$store.commit("menu/resetMenuState"); // Vuex의 resetMenuState 호출
      localStorage.removeItem("accessToken"); // 액세스 토큰 제거
      localStorage.removeItem("User"); // 다른 정보가 저장된 경우도 제거
      this.$router.replace("/auth/login");
    },
    profile() {
      this.$router.push({ name: "profile" });
    },
    // },
  },
};
</script>
